.countdown{
    display: flex;



width:auto;
}
.countdowndate{
 
    width:270px;
    font-size: 50px;
    text-decoration: none;
}
@media only screen and (max-width: 780px) {    
.countdowndate{
    font-size: 40px;
}
    .countdown{
        flex-direction: column;
        gap:60px;
    }
}