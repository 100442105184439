.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 50px;
    overflow-x: hidden;
  }
  .track {
    position: absolute;
    white-space: nowrap;
  }
  .track > h1 {
    /* margin: 20px 0; */
    font-size: 1rem;
    font-family: Antonio;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #f4955c;
    text-transform: uppercase;
  }